import React from 'react';
import SolutionPage from '../../components/SolutionPage';

const data = {
    heroSection: {
        label: {
            title: 'Part Number Identification', 
            imgUrl: 'https://looprdevstorage.blob.core.windows.net/website-temp/part-identification-label-logo.png', 
            imgDimensions: {
                width: '1.124rem', 
                height: '0.875rem'
            }, 
            mobileImgDimensions: {
                width: '0.67rem', 
                height: '0.65rem' 
            }
        }, 
        heading: [
            [
                {
                    text: 'Instantly scan', 
                    textColor: 'blue-800'
                },
                {
                    text: 'embossed,', 
                    textColor: 'black'
                }
            ],
            {
                text: 'printed or handwritten text', 
                textColor: 'black'
            }
        ], 
        content: "When batch shipments arrive, be ready with Loopr's part identification tool. Read alphanumeric text from thousands of parts or labels and save scanned data in any digital database.", 
        videoSrc: 'https://www.youtube.com/embed/GvNrWErVAf4'
    }, 
    problemsSection: {
        heading: 'Problems with manual data entry', 
        content: [
            {
                title: ['Slow'], 
                text: 'Manually reading and typing a long string of text is a tedious task', 
                imgUrl: 'https://looprdevstorage.blob.core.windows.net/website-temp/slow-icon.png'
            },
            {
                title: ['Error Prone'], 
                text: 'Human errors like copying a wrong digit is common and results in losing parts', 
                imgUrl: 'https://looprdevstorage.blob.core.windows.net/website-temp/error-prone-icon.png'
            },
            {
                title: ['High Costs'], 
                text: 'Limited output and lost parts increase production costs', 
                imgUrl: 'https://looprdevstorage.blob.core.windows.net/website-temp/high-costs-icon.png'
            }
        ]   
    }, 
    outcomesSection:{
        content: [
            {
                title: 'Precision', 
                text: 'Eliminate the need for manual data entry and identification of parts', 
                imgUrl: 'https://looprdevstorage.blob.core.windows.net/website-temp/precision-icon.png', 
                imgDimensions: {
                    width: '3.56rem', 
                    height: '3.63rem'
                }
            },
            {
                title: 'Lower costs', 
                text: 'Reduce costs associated with lost and missing parts arising from human errors', 
                imgUrl: 'https://looprdevstorage.blob.core.windows.net/website-temp/lower-cost-icon.png', 
                imgDimensions: {
                    width: '3.72rem', 
                    // height: '3.48rem', 
                    height: '3.63rem'
                }
            },
            {
                title: 'Increased Output', 
                text: 'Speed up processing of raw material and packaging of finished goods', 
                imgUrl: 'https://looprdevstorage.blob.core.windows.net/website-temp/increased-output-icon.png', 
                imgDimensions: {
                    width: '3.12rem', 
                    // height: '4.22rem'
                    height: '3.63rem'
                }
            },
        ]
    }, 
    automateSection: {
        heading: ['Automate part number identification', 'with AI-powered software'], 
        content: [
            {
                id: '01', 
                title: 'Customized for your parts', 
                text: [
                    "Loopr's camera-based software is customized to recognize alpha-numeric text from your parts",
                    'Identify part numbers of any size, font and color which are embossed, labeled, printed or etched on parts'
                ], 
                imgUrl: 'https://looprdevstorage.blob.core.windows.net/website-temp/part-identification-automate-01.png'
            },
            {
                id: '02', 
                title: 'Instant identification', 
                text: [
                    'Number or text embossed on a part is identified instantly, digitized and displayed on a screen',
                    "Digital part identifier is stored in your inventory database or worksheet and visible immediately"
                ], 
                imgUrl: 'https://looprdevstorage.blob.core.windows.net/website-temp/part-identification-automate-02.png'
            },
            {
                id: '03', 
                title: 'Accurate results each time', 
                text: [
                    'Get consistent output each time and never lose a part again from data entry errors',
                    'AI Software is updated with data of your parts to continuously improve its accuracy'
                ], 
                imgUrl: 'https://looprdevstorage.blob.core.windows.net/website-temp/part-identification-automate-03.png'
            },
        ]
    }, 
    platformSection: true
}

export default function PartIdentificationPage(){
    return  <SolutionPage data={data} />
}